import "@hotwired/turbo-rails"
import Rails from '@rails/ujs'
Rails.start()

import "../next/controllers"
import { StreamActions } from "@hotwired/turbo"
import TurboPower from 'turbo_power'

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

TurboPower.initialize(Turbo.StreamActions)

StreamActions.focus_element = function() {
  const value = this.getAttribute("value")
  const attributeName = this.getAttribute("attribute_name")

  const target = document.querySelector(`[${attributeName}="${value}"]`)

  if (!target) {
    console.warn(`The element with the id: "[${attributeName}="${value}"]" does not exist on the page.`);
    return;
  }

  const elementPosition = target.getBoundingClientRect().top + window.scrollY;

  window.scrollTo({
    top: elementPosition,
    behavior: 'smooth'
  });
}
