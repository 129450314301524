import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("keydown", this.closeWithEscape.bind(this));
    this.element.focus()
  }

  disconnect() {
    this.element.removeEventListener("keydown", this.closeWithEscape.bind(this));
  }

  // action: turbo-modal#hideModal
  hideModal() {
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
  }

  closeWithEscape(event) {
    if (event.key === "Escape") {
      this.hideModal();
    }
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (e && this.element.contains(e.target)) {
      return;
    }
    this.hideModal()
  }
}
