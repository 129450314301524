import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select', 'input'];
  static values = { mapping: Object };

  connect() {
    if (!this.hasMappingValue) {
      console.warn('No mapping provided to select-input controller');
    }
  }

  changeInput() {
    const selectedValue = this.selectTarget.value;

    if (this.hasMappingValue && selectedValue in this.mappingValue) {
      this.inputTarget.value = this.mappingValue[selectedValue];
    } else {
      this.inputTarget.value = selectedValue;
    }
  }
}
