import { Controller } from 'stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  request(e) {
    const url = e.currentTarget.dataset.radioNavigateUrlValue;

    console.log({url})
    fetch(url, { headers: { Accept: 'text/vnd.turbo-stream.html' } })
      .then((r) => r.text())
      .then((html) => Turbo.renderStreamMessage(html));
  }
}

