import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['slot'];

  connect() {
    console.log(this.slotTargets);
    this.slotTargets.forEach((slot) => {
      slot.addEventListener('click', (e) => {
        this.slotTargets.forEach((slot) => {
          slot.classList.remove('border-new-brand', 'bg-new-brand', 'text-white', 'border-new-brand');
          slot.classList.add('border-secondary');
        });
        slot.classList.remove('border-secondary')
        slot.classList.add('border-new-brand', 'bg-new-brand', 'text-white', 'border-new-brand');
      });
    });
  }
}
