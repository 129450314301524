import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item', 'container'];

  connect() {
    this.observer = new IntersectionObserver(
      (entries) => {
        const highest = entries.reduce((highest, current) => {
          return current.intersectionRatio > highest.intersectionRatio ? current : highest;
        }, entries[0]);

        console.log({ highest: highest.target  });
        entries.forEach((entry) => {
          // DEBUG
          //const intersecting = entry.isIntersecting
          //entry.target.style.backgroundColor = intersecting ? "blue" : "orange"

          if (entry.isIntersecting && entry.intersectionRatio > 0.5) {
            console.log(entry.target, `${Math.round(entry.intersectionRatio * 100)}%`);
            this.items.forEach(
              (item) => {
                if (item.section === entry.target) {
                  const left = item.index === 0 ? 0 : item.item.offsetLeft;
                  this.containerTarget.scrollTo({left: left, behavior: 'smooth'});
                  item.item.classList.add('bg-new-brand');
                  item.item.classList.add('text-white');
                } else {
                  item.item.classList.remove('bg-new-brand');
                  item.item.classList.remove('text-white');
                }
              }
            );
          }
        });
      },
      { margin: '0px', threshold: 1}
    );
    this.items = this.itemTargets.map((item, index) => {
      return ({
        item,
        index,
        section: document.querySelector(item.hash),
      });
    });
    this.items.forEach(({ section }) => this.observer.observe(section));
  }

  disconnect() {
    this.items.forEach(({ section }) => this.observer.unobserve(section));
  }
}
