import { Controller } from "@hotwired/stimulus";
import { put } from "@rails/request.js";
import Sortable from "sortablejs";

/*
 * Usage
 * =====
 *
 * add data-controller="sortable" to common ancestor
 *
 * 1. Add data-sortable-url-value="URL to update position" to the
 * controller container
 *
 * 2. Add data-sortable-handle to the drag handle:
 *
 * 3. Add data-sortable-id to the item
 *
 */
export default class extends Controller {
  static values = { url: String };

  connect() {
    this.sortable = Sortable.create(this.element, {
      animation: 150,
      onEnd: this.onEnd.bind(this),
      handle: "[data-sortable-handle]",
      onChange: () => this.orderIndexes()
    })
  }

  disconnect() {
    this.sortable.destroy()
  }

  onEnd(event) {
    const item = event.item
    const newIndex = event.newIndex
    const id = item.dataset["sortableId"]
    const url = this.urlValue.replace(":id", id)
    put(url, {
      body: JSON.stringify({ position: newIndex })
    }).then(response => {
      if (response.ok) {
        this.orderIndexes()
      }
    })
  }

  orderIndexes() {
    const items = this.element.querySelectorAll("[data-sortable-id]")
    items.forEach((item, index) => {
      item.querySelector("[data-sortable-index]").textContent = index + 1
    })
  }
}