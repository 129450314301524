import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['toggleable', 'button', 'arrowDown', 'arrowUp'];
  static values = { open: Boolean };

  connect() {
    console.log('toggle connect', { openValue: this.openValue });
    if (this.openValue) {
      this.open();
    } else {
      this.close();
    }
  }

  toggle() {
    this.openValue = !this.openValue;

    console.log({ openValue: this.openValue })
    if (this.openValue) {
      this.open();
    } else {
      this.close();
    }
  }

  close() {
    this.toggleableTarget.classList.add('hidden');
    this.buttonTarget.classList.add('rounded');
    this.buttonTarget.classList.remove('rounded-tl');
    this.arrowDownTarget.classList.remove('hidden');
    this.arrowUpTarget.classList.add('hidden');
  }

  open() {
    this.toggleableTarget.classList.remove('hidden');
    this.buttonTarget.classList.remove('rounded');
    this.buttonTarget.classList.add('rounded-tl', 'rounded-tr');
    this.arrowDownTarget.classList.add('hidden');
    this.arrowUpTarget.classList.remove('hidden');
  }
}
