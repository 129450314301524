import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    this.element.addEventListener('click', this.removeElement)
  }

  removeElement(event) {
    event.preventDefault()
    event.target.closest('turbo-frame').remove()
  }
}