import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'input',
    'previewImage',
    'previewDocument',
    'hint',
    'remove',
    'name',
    'keep',
    'tooLarge'
  ]

  preview() {
    this.readURL(this)
  }

  readURL(e) {
    let that = this
    if (this.inputTarget.files && this.inputTarget.files[0]) {
      var reader = new FileReader()
      reader.onload = function (e) {
        if (e.target.result != undefined) {
          // if file too large, reject it and display error (10MB max)
          if (that.inputTarget.files[0].size > 10485760) {
            that.remove()
            that.tooLargeTarget.classList.remove('hidden')
            that.tooLargeTarget.innerHTML = "Fichier trop volumineux. Taille maximum 10Mo."
            return
          } else {
            that.tooLargeTarget.classList.add('hidden')
          }
          if (e.target.result.match(/^data:image/)) {
            that.previewImageTarget.style.backgroundImage = `url('${e.target.result}')`
            that.previewImageTarget.classList.remove('hidden')
          } else {
            that.nameTarget.innerHTML = that.inputTarget.files[0].name
            that.previewDocumentTarget.classList.remove('hidden')
          }
          that.removeTarget.classList.remove('hidden')
          that.hintTarget.classList.add('hidden')
        } else {
          that.previewDocumentTarget.classList.add('hidden')
          that.previewImageTarget.classList.add('hidden')
          that.removeTarget.classList.add('hidden')
          that.hintTarget.classList.remove('hidden')
        }
      }

      var file = reader.readAsDataURL(this.inputTarget.files[0])
    }
  }

  remove() {
    this.inputTarget.value = null
    this.previewImageTarget.style.backgroundImage = "none"
    this.previewImageTarget.classList.add('hidden')
    this.previewDocumentTarget.classList.add('hidden')
    this.removeTarget.classList.add('hidden')
    this.hintTarget.classList.remove('hidden')
    this.keepTarget.value = "0"
  }
}
