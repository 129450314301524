import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    numberOfItem: { type: Number, default: 0 }
  };

  static targets = ['numberOfItemDisplay', 'decrementButton', 'incrementButton']

  connect() {
    this.hideShowButton();
    this.numberOfItemDisplayTarget.innerHTML = this.numberOfItemValue;
  }

  increment() {
    this.numberOfItemValue = this.numberOfItemValue + 1;

    this.hideShowButton();
    this.numberOfItemDisplayTarget.innerHTML = this.numberOfItemValue;
  }

  decrement() {
    if (this.numberOfItemValue === 0) {
      return;
    }

    this.numberOfItemValue = this.numberOfItemValue - 1;
    this.hideShowButton();
    this.numberOfItemDisplayTarget.innerHTML = this.numberOfItemValue;
  }

  hideShowButton() {
    if (this.numberOfItemValue === 0) {
      this.decrementButtonTarget.classList.add('hidden');
      this.numberOfItemDisplayTarget.classList.add('hidden');
      return;
    }

    this.decrementButtonTarget.classList.remove('hidden');
    this.numberOfItemDisplayTarget.classList.remove('hidden');
  }
}
