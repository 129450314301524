import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["days", "hours", "minutes", "seconds"];

  initialize() {
    this.endTime = new Date(this.data.get("endTime"));
    this.interval = null;
  }

  connect() {
    this.update();
    this.interval = setInterval(() => {
      this.update();
    }, 1000);
  }

  disconnect() {
    clearInterval(this.interval);
  }

  update() {
    const now = new Date();
    const diff = Math.max(this.endTime - now, 0);
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    const formatTime = (unit) => unit.toString().padStart(2, "0");

    this.daysTarget.textContent = formatTime(days);
    this.hoursTarget.textContent = formatTime(hours);
    this.minutesTarget.textContent = formatTime(minutes);
    this.secondsTarget.textContent = formatTime(seconds);
  }
}
